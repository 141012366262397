import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Helpers
import useFetch from 'use-http';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

// Custom UI
import PageMasthead from '../../components/PageMasthead';
import PageContainerWide from '../../components/PageContainerWide';
import ProductsGrid from '../products/components/ProductsGrid';
import Alert from '../../components/Alert';

// Hooks
import { useTheme } from '../../theme/ThemeContext'

export default function LandingPage() {

  const { t } = useTranslation();
  const theme = useTheme();

  const [products, setProducts] = useState([]);
  const { post, response, error, loading } = useFetch('swap-products/search');
  const pageSize = 12;

  useEffect(() => {
    async function getProducts() {
      const products = await post({ limit: pageSize });
      if (response.ok) {
        setProducts(products.records);
      }
    };
    getProducts();
  }, []);

  return (
    <>
      <PageMasthead>
        <Typography component="h1" variant="h1" align='center'>
          {t('page_landing.text_title', {title: theme.settings.title})}
        </Typography>
        <Typography component="p" variant="body1" align='center'>
          {t('page_landing.text_subtitle')}
        </Typography>
        <Button align='center' component={RouterLink} color="primary" replace={false} to="/auth/login" fullWidth={true} variant="contained">
          {t('page_landing.btn_signin')}
        </Button>
        <Typography variant="p" align='center'>
          {t('page_landing.text_account')} <Link target="_blank" href={`${theme.settings.shopifyUrl}/account/login`}>{t('page_landing.link_signup')}</Link>
        </Typography>
      </PageMasthead>
      { !error &&
        <PageContainerWide>
          <ProductsGrid products={products} heading={t('page_landing.label_product_grid')} isLoading={loading}/>
        </PageContainerWide>
      }
      { error &&
        <Alert severity="error">{t('global.text_error')}</Alert>
      }
    </>
  );

}
