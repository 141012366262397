import { createTheme } from '@mui/material/styles';

const bodyFont = "'canada-type-gibson', sans-serif";
const headingFont = "'canada-type-gibson', sans-serif";

export const settings = {
  displayName: 'This is Unfolded',
  name: 'unfolded',
  title: 'Swaps by Unfolded',
  logo: 'Logo.svg',
  banner: 'Banner.jpg',
  shop: 'thisisunfolded.myshopify.com',
  shopifyUrl: 'https://thisisunfolded.com',
  supportEmail: 'support@thisisunfolded.com'
};

export const customTheme= createTheme({
  typography: {
    fontFamily: bodyFont,
    color: '#003B4A',
    h1: {
      fontFamily: headingFont,
      fontSize: 24,
      fontWeight: 'var(--font-heading-weight)',
      color: '#003B4A',
      textTransform: 'uppercase'
    },
    h2: {
      fontFamily: headingFont,
      fontSize: 18,
      fontWeight: 'var(--font-heading-weight)',
      color: '#003B4A',
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: headingFont,
      fontSize: 16,
      fontWeight: 'var(--font-heading-weight)',
      color: '#003B4A'
    },
    body1: {
      fontSize: 16,
      color: '#003B4A',
    },
    body1_bold: {
      fontSize: 16,
      color: '#003B4A',
      fontWeight: 'var(--font-body-bold-weight)'
    },
    body2: {
      fontSize: 12,
      color: '#003B4A',
    },
    body2_bold: {
      fontSize: 12,
      color: '#003B4A',
      fontWeight: 'var(--font-body-bold-weight)'
    },
    body3: {
      fontSize: 16,
      color: '#5F848D',
    },
    smalllabel: {
      fontSize: 12,
      color: '#B9B9B9',
    },
    action: {
      fontFamily: bodyFont
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#007896',
    },
    primary_dark: {
      main: '#003B4A',
    },
    secondary: {
      main: '#EEF0EE',
    },
    warning: {
      main: '#FDE6D8'
    },
    action: {
      fontFamily: bodyFont,
      disabledBackground: '#eef0ee',
      disabled: '#fff',
      active: '#007896',
      link: '#007896'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: bodyFont,
          fontSize: 20,
          fontWeight: 'var(--font-body-weight)'
        },
        sizeSmall: {
          fontSize: '0.8rem',
          padding: '3px 1rem',
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff'
        }
      }
    }
  }
});

