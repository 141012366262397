import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// MUI
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

// Icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Custom UI
import Spinner from '../../../components/Spinner';
import Alert from '../../../components/Alert';

// Helpers
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Context
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../theme/ThemeContext';

export default function ResetPasswordFields() {

  const { t } = useTranslation();
  const auth = useAuth();
  const theme = useTheme();

  const location = useLocation();
  const history = useHistory();

  const urlParams = new URLSearchParams(location.search);

  const [isSettingPasswords, setIsSettingPasswords] = useState(false);
  const [successfullPasswordReset, setSuccessfullPasswordReset] = useState(false);
  const [hasFormError, setHasFormError] = useState(false);
  const [hasAPIError, setHasAPIError] = useState(false);
  const [token, setToken] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const urltoken = urlParams.get('token');

  const CentreItem = styled(Container)(({ theme }) => ({
    textAlign: 'center',
  }));

  useEffect(() => {
    if (urltoken) {
      setToken(urltoken);
    } else {
      // No token, push user back to login
      history.push({
        pathname: './login'
      })
    }
  }, [urltoken]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const { handleSubmit, control, register, formState: { errors } } = useForm({
    defaultValues: {
      password1: '',
      password2: ''
    }
  });

  const onSubmit = async (params) => {

    if (params.password1 !== params.password2 || !token) {
      setHasFormError(true);
      return;
    }

    setIsSettingPasswords(true);

    const config = {
      password: params.password1,
      password_confirm: params.password2,
      shop: params.shop,
      token: token
    }

    try {
      const reset = await auth.resetPassword(config);
      if (reset) {
        setSuccessfullPasswordReset(true);
      } else {
        setIsSettingPasswords(false);
        setHasAPIError(true);
      }
    } catch (error) {
      setHasFormError(true);
      setIsSettingPasswords(false);
      console.log('Password reset failed', error);
    }
  }

  if (successfullPasswordReset) {
    return (
      <Container>
        <Stack spacing={2} sx={{ margin: "1rem 2rem" }}>
          <Typography component="h1" variant="h1" align='center'>{t('page_password_reset.text_success_title')}</Typography>
          <Typography component="p" variant="body1" align='center'>{t('page_password_reset.text_success')}</Typography>
          <Button component={RouterLink} to="/auth/login" variant="outlined" fullWidth={true}>
            {t('page_password_reset.btn_login')}
          </Button>
        </Stack>
      </Container>
    )
  }

  return (
    <Container>
      <FormProvider>
        { isSettingPasswords ? <Spinner /> :
          <CentreItem>
            <Stack spacing={2}>
              <Typography component="h1" variant="h1">{t('page_password_reset.text_title')}</Typography>
              <Typography component="p" variant="body1" align='center'>{t('page_password_reset.text_body')}</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <input type="hidden" id="shop" name="shop" value={theme.settings.shop} {...register('shop')} />
              <Stack spacing={1} sx={{ margin: "1rem 2rem" }}>
                <Controller
                  name="password1"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      required={true}
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('page_password_reset.field_password_1')}
                      error={!!errors.password1}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <Controller
                  name="password2"
                  control={control}
                  render={({ field }) => (
                    <OutlinedInput
                      {...field}
                      required={true}
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('page_password_reset.field_password_2')}
                      error={!!errors.password2}
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <Button type="submit" variant="contained" fullWidth>{t('page_password_reset.btn_set_password')}</Button>
              </Stack>
            </form>
          </CentreItem>
        }
      </FormProvider>
      { hasFormError && !isSettingPasswords &&
        <Alert severity="warning" showPatch={false} message={t('page_password_reset.text_error_match')} />
      }
      { hasAPIError && !isSettingPasswords &&
        <Alert severity="warning" showPatch={false} message={t('page_password_reset.text_error_token')} />
      }
    </Container>
  );
}
