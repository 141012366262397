import React from 'react';
import { Route, Switch } from "react-router-dom";

// MUI
import Container from '@mui/material/Container';

// Custom UI
import Navigation from '../components/Navigation';

// Contexts
import { LoadingProgressBar } from '../context/LoadingContext';

// Pages
import TermsPage from '../pages/account/TermsPage';
import LoginPage from '../pages/login/LoginPage';
import ConfirmPage from '../pages/login/ConfirmPage';
import ForgottenPasswordPage from '../pages/login/ForgottenPasswordPage';
import ResetPasswordPage from '../pages/login/ResetPasswordPage';

export default function AuthLayout({ match }) {
  return (
    <>
      <Navigation />
      <LoadingProgressBar />
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Switch>
          <Route path={`${match.url}/confirm`} render={(props) => (<ConfirmPage {...props} />)} />
          <Route path={`${match.url}/login`} render={(props) => (<LoginPage {...props} />)} />
          <Route path={`${match.url}/forgotten-password`} render={(props) => (<ForgottenPasswordPage {...props} />)} />
          <Route path={`${match.url}/reset-password`} render={(props) => (<ResetPasswordPage {...props} />)} />
          <Route path={`${match.url}/terms`} render={(props) => (<TermsPage {...props} />)} />
        </Switch>
      </Container>
    </>
  );
}
